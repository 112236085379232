<template>
  <div v-if="false"/>
</template>

<script>
import MixinSearchFilter from '@/components/mixins/SearchFilter'

export default {
  name: 'SearchFilterHidden',
  mixins: [MixinSearchFilter]
}
</script>

<style lang="scss">
.search-filter.filter-hidden {}
</style>
