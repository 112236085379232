<template>
  <div :class="['search-filter', 'filter-colors', `type-${filter.type}`, { 'is-expanded': expanded }]">
    <b-button class="filter-toggle" variant="link" block @click="toggle()">
      {{ filter.displayName }} <lazy-icon icon="caret-down" scale="0.6"/>
    </b-button>

    <transition-expand name="filterexpander" :expanded="expanded">
      <div v-show="expanded" class="filter-content">
        <div>
          <vue-scroll :ops="scrollOptions" ref="scrollbar">
            <div class="filter-controls">
              <div
                v-for="control in filter.controls.filter((color, index) => filter.controls.findIndex(dc => dc.id === color.id) === index)"
                :key="control.id"
                :id="`FilterControl-${_uid}-${control.id}`"
                class="filter-control"
              >
                <b-form-checkbox
                  :id="control.id"
                  v-model="control.value"
                  @change="$value => updateControl(control, $value)"
                >
                  <component :is="control.value ? 'strong' : 'span'">{{ control.text }}</component><span class="control-count">{{ $n(control.count, 'decimal') }}</span>
                </b-form-checkbox>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
    </transition-expand>

    <div class="d-none" v-html="controlStyles"/>
  </div>
</template>

<script>
import SCSS_VARIABLES from '@/assets/js/modules/scss-variables'
import { toHex, contrast } from '@/assets/js/helper/color'

import VueScroll from 'vuescroll/dist/vuescroll-native'
import MixinSearchFilter from '@/components/mixins/SearchFilter'
import TransitionExpand from '@/components/private/TransitionExpand'

const CHECKBOX_INDICATOR_ICON = SCSS_VARIABLES.vars['custom-checkbox-indicator-icon-checked-small']
const CHECKBOX_INDICATOR_ICON_COLOR = SCSS_VARIABLES.vars['custom-control-indicator-checked-color']

const SVG_ESCAPE_CHARACTER_MAP = SCSS_VARIABLES.vars['escaped-characters']
  .reduce((map, v, i) => {
    i % 2 === 0 ? map.push({ char: v }) : map[map.length - 1].encoded = v
    return map
  }, [])

export default {
  name: 'SearchFilterColors',
  mixins: [MixinSearchFilter],
  components: {
    VueScroll,
    TransitionExpand
  },
  data () {
    return {
      // https://vuescrolljs.yvescoding.org/guide/configuration.html
      // https://github.com/YvesCoding/vuescroll/blob/dev/src/shared/global-config.js
      scrollOptions: {
        sizeStrategy: 'percent',
        detectResize: true,
        locking: true,
        maxHeight: undefined,
        scrollPanel: {
          initialScrollY: false,
          initialScrollX: false,
          scrollingY: true,
          scrollingX: false,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: 'right',
          maxHeight: this.$root.isDesktop ? 312 : undefined,
          maxWidth: undefined
        },
        rail: {
          background: SCSS_VARIABLES.colors.theme.control,
          border: 'none',
          opacity: 1,
          size: '6px',
          specifyBorderRadius: false,
          gutterOfEnds: '0px',
          gutterOfSide: '0px',
          keepShow: false
        },
        bar: {
          disable: false,
          background: SCSS_VARIABLES.colors.theme.dark,
          opacity: 1,
          size: '6px',
          showDelay: 0,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0,
          onlyShowBarOnScroll: false,
          keepShow: true
        },
        scrollButton: {
          enable: false,
          background: SCSS_VARIABLES.colors.theme.dark,
          opacity: 1,
          step: 50,
          mousedownStep: 30
        },
        vuescroll: {
          wheelScrollDuration: 500,
          wheelDirectionReverse: false,
          checkShifKey: true
        }
      }
    }
  },
  computed: {
    controlStyles () {
      const controls = this.filter.controls
        .map(c => ({
          id: `#FilterControl-${this._uid}-${c.id}`,
          backgroundColor: toHex(c.data.additionalData),
          fill: contrast(c.data.additionalData)
        }))

      const controlBackgroundStyles = controls
        .map(c => `${c.id} .custom-control-input:not(:disabled) ~ .custom-control-label::before { background-color: ${c.backgroundColor}; }`)
        .join('\n\r')

      const checkedIconGroups = controls
        .reduce((fills, c) => Object.assign(fills, { [`fill-${c.fill}`]: [].concat(fills[`fill-${c.fill}`] || [], c) }), {})

      const checkedIconStyles = Object.values(checkedIconGroups)
        .map(g => {
          const CHECKBOX_INDICATOR_ICON_COLOR_OVERWRITE = g[0].fill

          return g
            .map(c => `${c.id} .custom-checkbox .custom-control-input:checked ~ .custom-control-label::after`)
            .join(',\n')
            .concat(` { background-image: ${this.escapeSvg(CHECKBOX_INDICATOR_ICON.replace(`fill='${CHECKBOX_INDICATOR_ICON_COLOR}'`, `fill='${CHECKBOX_INDICATOR_ICON_COLOR_OVERWRITE}'`))}; }`)
        })
        .join('\n\r')

      return `<style>${controlBackgroundStyles}\n\r${checkedIconStyles}</style>`
    }
  },
  methods: {
    escapeSvg (string = '') {
      if (string.indexOf('data:image/svg+xml') >= 0) {
        SVG_ESCAPE_CHARACTER_MAP
          .forEach(v => {
            if (string.indexOf('url(') === 0) {
              string = `url(${string.slice(4, -1).replace(new RegExp(`\\${v.char}`, 'g'), v.encoded)})`
            } else {
              string = string.replace(v.key, v.value)
            }
          })
      }

      return string
    }
  },
  watch: {
    '$root.mobilefirstBreakpoint' () {
      this.scrollOptions.scrollPanel.maxHeight = this.$root.isDesktop ? 312 : undefined
      this.$refs.scrollbar.refresh()
    }
  }
}
</script>

<style lang="scss">
$searchfilter-colors-control-border-width: 1px !default;
$searchfilter-colors-control-border-color: $control !default;

.search-filter.filter-colors {
  .filter-control {
    .custom-control-input {
      ~ .custom-control-label {
        &:before,
        &:after {
          border-radius: 50%;
        }
      }

      &:not(:checked) {
        ~ .custom-control-label {
          &:before {
            border-width: $searchfilter-colors-control-border-width;
            border-color: $searchfilter-colors-control-border-color;
          }
        }
      }
    }
  }
}
</style>
