<template>
  <div :class="['search-filter', 'filter-text', `type-${filter.type}`, { 'is-expanded': expanded }]">
    <b-button class="filter-toggle" variant="link" block @click="toggle()">
      {{ filter.displayName }} <lazy-icon icon="caret-down" scale="0.6"/>
    </b-button>

    <transition-expand name="filterexpander" :expanded="expanded" @after-enter="setControlFocus">
      <div v-show="expanded" class="filter-content">
        <div class="filter-controls">
          <div
            v-for="control in filter.controls"
            :key="control.id"
            class="filter-control"
          >
            <b-form-input
              :id="control.id"
              :value="control.value"
              size="xs"
              ref="control"
              debounce="250"
              @input="$value => updateControl(control, $value)"
              @keydown.native.esc="resetControls"
            />
          </div>
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import MixinSearchFilter from '@/components/mixins/SearchFilter'
import TransitionExpand from '@/components/private/TransitionExpand'

export default {
  name: 'SearchFilterText',
  mixins: [MixinSearchFilter],
  components: {
    TransitionExpand
  },
  methods: {
    setControlFocus () {
      this.$refs.control[0].$el.focus()
    }
  }
}
</script>

<style lang="scss">
.search-filter.filter-text {}
</style>
