<template>
  <div class="search" sticky-wrapper>
    <c-anker name="search" />

    <b-row>
      <b-col :[$root.md.key]="3">
        <aside>
          <nav ref="categoryListNavigation" v-if="$slots.categorylist" class="search-categorylist">
            <slot name="categorylist" />
          </nav>

          <search-filter-header v-if="$root.isDesktop" :translationsPath="`${tPath}.filter`">
            <template #resultcount>{{ translationResultsCount }}</template>
          </search-filter-header>

          <search-filter-menu :translationsPath="`${tPath}.filter`" />
        </aside>
      </b-col>

      <b-col :[$root.md.key]="9">
        <section>
          <div v-if="$slots.introcontent && !(query || selectedQueryFilters)" class="search-introcontent">
            <slot name="introcontent" />
          </div>

          <search-filter-header v-if="!$root.isDesktop" :translationsPath="`${tPath}.filter`">
            <template #query>
              <i18n v-if="query" :path="`${tPath}.query`" tag="h1" class="h5 font-regular">
                <template #query><strong>{{ query }}</strong></template>
              </i18n>
            </template>

            <template #resultcount>{{ translationResultsCount }}</template>

            <template #sorting>
              <search-sorting :translationsPath="`${tPath}.sorting`" />
            </template>
          </search-filter-header>

          <template v-if="$root.isDesktop">
            <i18n v-if="query || selectedQueryFilters" :path="`${tPath}.query.title`" tag="h1" class="h4 font-bold">
              <template #query>
                <template v-if="query">&laquo;{{ query }}&raquo;</template>
                <template v-if="query && selectedQueryFilters">, </template>
                <template v-if="selectedQueryFilters">
                  <span v-for="(singleFilter, key) in selectedQueryFilters" :key="`SelectedQueryFilter_${key}`">{{ singleFilter.label }} {{ singleFilter.value }}<template v-if="key < selectedQueryFilters.length - 1">, </template></span></template>
              </template>
            </i18n>

            <search-sorting :translationsPath="`${tPath}.sorting`" />
          </template>

          <div class="search-results" :style="{ 'min-height': searchResultsMinHeightPixels + 'px' }">
            <loader v-if="!isInitialized" />

            <div v-else-if="!results.length" class="results-noresuls">
              <slot name="noresults" />
            </div>

            <component v-else :is="resultComponent" :searchType="searchType" :viewMode="viewMode" :results="results" :showShoppingCart="showShoppingCart" :translationsPath="`${tPath}.results`" :hasPromobox="promoboxDisplayIndex >= 0" :promoboxIndex="promoboxDisplayIndex" :isLastPage="!canLoadmore">
              <template v-slot:promobox>
                <slot name="promobox" />
              </template>
            </component>
          </div>

          <div v-if="results.length" class="search-loadmore">
            <b-row align-v="center" align-h="between">
              <b-col :[$root.mt.key]="'auto'">
                <div>{{ translationResultsSubset }}</div>
              </b-col>

              <b-col :[$root.mt.key]="'auto'">
                <b-button variant="primary" :disabled="!canLoadmore || is.loading" @click="loadMore">
                  <loader v-if="is.loading" inline /> {{ $t(`${tPath}.results.loadmore`) }}
                </b-button>
                <slot name="afterloadmorebutton" />
              </b-col>
            </b-row>
          </div>

          <div class="search-scrolltop">
            <a href="#search">{{ $t(`${tPath}.scrolltop`) }} <lazy-icon icon="arrow-long-up" /></a>
          </div>

          <div v-if="$slots.outrocontent" class="search-outrocontent">
            <slot name="outrocontent" />
          </div>
        </section>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { COMPONENT_SEARCH_FILTER_MAPPER_TYPES_MAP, COMPONENT_SEARCH_TYPES } from '@/constants'

import Loader from '@/components/private/Loader'
import SearchFilterHeader from '@/components/private/SearchFilterHeader'
import SearchFilterMenu from '@/components/private/SearchFilterMenu'
import SearchResulttypeProduct from '@/components/private/SearchResulttypeProduct'
import SearchSorting from '@/components/private/SearchSorting'
import SearchResulttypeUnknown from '@/components/private/UnknownDynamicComponent'

const SEARCH_TYPE_KEYS = Object.keys(COMPONENT_SEARCH_TYPES)
const SEARCH_TYPE_DEFAULT_KEY = SEARCH_TYPE_KEYS.find((oKey) => COMPONENT_SEARCH_TYPES[oKey].isDefault) || SEARCH_TYPE_KEYS[0]
const RANGE_FILTER_TYPES = COMPONENT_SEARCH_FILTER_MAPPER_TYPES_MAP.range

export default {
  name: 'Search',
  components: {
    Loader,
    SearchFilterHeader,
    SearchFilterMenu,
    SearchSorting,
    SearchResulttypeProduct,
    SearchResulttypeUnknown
  },
  props: {
    searchType: {
      type: String,
      default: SEARCH_TYPE_DEFAULT_KEY
    },
    initialFilters: {
      type: Array,
      default: () => []
    },
    extendedHiddenFilters: {
      type: Array,
      default: () => []
    },
    showShoppingCart: {
      type: Boolean,
      default: false
    },
    showSingleFilterTitle: {
      type: Boolean,
      default: false
    },
    promoboxDisplayIndex: {
      type: Number,
      default: -1
    }
  },
  data() {
    return {
      isInitialized: false,
      resultComponent: `search-resulttype-${((COMPONENT_SEARCH_TYPES[this.searchType] || {}).result || {}).type || 'unknown'}`
    }
  },
  computed: {
    is() {
      return this.$store.getters['search/is']
    },
    statistics() {
      return this.$store.getters['search/statistics']
    },
    query() {
      return this.$store.getters['search/query']
    },
    selectedQueryFilters() {
      const activeFilters = this.$store.getters['search/getActiveFilters']
      if (!this.showSingleFilterTitle || activeFilters.length === 0) {
        return null
      }

      return activeFilters
        .filter((f) => f.controls.length > 0)
        .map((f) => {
          return {
            label: f.displayName,
            value: f.controls.join(!RANGE_FILTER_TYPES.includes(f.type) ? ', ' : ' - ')
          }
        })
    },
    results() {
      return this.$store.getters['search/get']
    },
    renderCategories() {
      return this.$store.getters['search/getRenderCategories']
    },
    resultsLoaded() {
      return this.$store.getters['search/getLoadedCount']
    },
    canLoadmore() {
      return this.$store.getters['search/getCanLoadMore']
    },
    translationResultsCount() {
      return this.$t(`${this.tPath}.results.count${['0', '1', 'x'][Math.min(this.statistics.total.items, 2)]}`, {
        count: this.$n(this.statistics.total.items, 'decimal')
      })
    },
    translationResultsSubset() {
      return this.$t(`${this.tPath}.results.subsetcount${['0', '1', 'x'][Math.min(this.resultsLoaded, 2)]}`, {
        subset: this.$n(this.resultsLoaded, 'decimal'),
        count: this.$n(this.statistics.total.items, 'decimal')
      })
    },
    searchResultsMinHeightPixels() {
      const pageNumber = this.$store.getters['search/initialPage']
      if (pageNumber <= 1 || this.isInitialized) {
        return 1 * 370 + 60 // 1 row with 3 items each and 60px margin from the container as default
      }

      const itemsPerPage = this.statistics.page.size
      const totalItems = pageNumber * itemsPerPage

      // Calcuate a approx height for each item with 370px and add 60px margin from the container
      const result = Math.ceil(totalItems / 3) * 370 + 60
      return result
    },
    viewMode() {
      return this.$store.getters['search/getViewMode']
    }
  },
  methods: {
    loadMore() {
      this.$store.dispatch('search/getNextSearchPage')
    },
    handleCategories() {
      if (this.renderCategories) {
        const categoryIds = this.results
          .map((x) => x.categoryIds)
          .flat()
          .filter((v, i, a) => a.indexOf(v) === i)
        categoryIds.forEach((x) => {
          const categoryItem = this.$refs.categoryListNavigation.querySelector('[data-categoryid="' + x + '"]')
          if (categoryItem) {
            categoryItem.classList.remove('d-none')
          }
        })
      }
    }
  },
  watch: {
    renderCategories() {
      this.handleCategories()
    },
    canLoadmore(value) {
      if (value === false) {
        const relElement = document.querySelector('link[rel=next]')
        if (relElement) {
          relElement.remove()
        }
      }
    }
  },
  created() {
    this.$store
      .dispatch('search/init', {
        searchType: this.searchType,
        filters: this.initialFilters,
        extendedHiddenFilters: this.extendedHiddenFilters
      })
      .finally(() => {
        this.isInitialized = true
        this.handleCategories()
      })
  }
}
</script>

<style lang="scss">
// mobile

$search-results-noresults-padding-y: $component-padding-y !default;
$search-results-noresults-padding-x: 0 !default;
$search-results-noresults-text-align: center !default;

$search-introcontent-gap: $grid-gutter-width !default;
$search-outrocontent-gap: 0 !default;
$search-outrocontent-font-weight: $font-weight-bold !default;
$search-results-gap: $grid-gutter-width * 0.5 !default;
$search-loadmore-gap: $spacer !default;
$search-scrolltop-gap: $grid-gutter-width !default;

// desktop

$search-desktop: $desktop-breakpoint !default;

$search-desktop-categorylist-item-gap: $spacer * 1.5 !default;
$search-desktop-categorylist-sub-item-margin: $spacer !default;
$search-desktop-categorylist-item-padding-y: $spacer * 0.25 !default;
$search-desktop-categorylist-item-padding-x: $spacer * 0.8 !default;
$search-desktop-categorylist-item-border: set-nth(map-get($borders, 'bold'), 3, transparent) !default;
$search-desktop-categorylist-item-font-size: inherit !default;
$search-desktop-categorylist-sub-item-font-size: 0.95em !default;
$search-desktop-categorylist-item-font-weight: $font-weight-base !default;
$search-desktop-categorylist-item-color: inherit !default;
$search-desktop-categorylist-item-line-height: 1.2 !default;

$search-desktop-categorylist-item-hover-border: set-nth($search-desktop-categorylist-item-border, 3, $primary) !default;
$search-desktop-categorylist-item-hover-font-size: $search-desktop-categorylist-item-font-size !default;
$search-desktop-categorylist-item-hover-font-weight: $font-weight-bold !default;
$search-desktop-categorylist-item-hover-color: $search-desktop-categorylist-item-color !default;

$search-desktop-categorylist-item-active-border: $search-desktop-categorylist-item-hover-border !default;
$search-desktop-categorylist-item-active-font-size: $search-desktop-categorylist-item-hover-font-size !default;
$search-desktop-categorylist-item-active-font-weight: $search-desktop-categorylist-item-hover-font-weight !default;
$search-desktop-categorylist-item-active-color: $search-desktop-categorylist-item-hover-color !default;

$search-desktop-results-noresults-padding-y: $component-desktop-padding-y !default;
$search-desktop-results-noresults-padding-x: 0 !default;
$search-desktop-results-noresults-text-align: $search-results-noresults-text-align !default;

$search-desktop-introcontent-gap: $grid-gutter-width !default;
$search-desktop-outrocontent-gap: $search-desktop-introcontent-gap !default;
$search-desktop-results-gap: $grid-gutter-width !default;
$search-desktop-loadmore-gap: $spacer !default;
$search-desktop-scrolltop-gap: $grid-gutter-width !default;

.search {
  @include media-breakpoint-down(get-breakpoint-key($search-desktop, -1)) {
    .search-categorylist {
      @include sr-only();
    }

    .search-introcontent {
      margin-bottom: $search-introcontent-gap;
    }

    .search-results {
      margin-top: $search-results-gap;
      margin-bottom: $search-results-gap;

      .results-noresuls {
        padding: $search-results-noresults-padding-y $search-results-noresults-padding-x;
        text-align: $search-results-noresults-text-align;
      }
    }

    .search-loadmore {
      margin-bottom: $search-loadmore-gap;
      text-align: center;

      .btn {
        margin-top: $search-loadmore-gap;
      }
    }

    .search-scrolltop {
      margin-bottom: $search-scrolltop-gap;
      text-align: right;
    }

    .search-outrocontent {
      margin-bottom: $search-outrocontent-gap;
    }
  }

  @include media-breakpoint-up($search-desktop) {
    .search-categorylist {
      margin-bottom: $search-desktop-categorylist-item-gap;

      ul {
        @include list-unstyled();

        > li {
          padding: $search-desktop-categorylist-item-padding-y 0;

          > a {
            display: inline-block;
            padding: 0 $search-desktop-categorylist-item-padding-x;
            border-left: $search-desktop-categorylist-item-border;
            font-size: $search-desktop-categorylist-item-font-size;
            font-weight: $search-desktop-categorylist-item-font-weight;
            color: $search-desktop-categorylist-item-color;
            line-height: $search-desktop-categorylist-item-line-height;
            text-decoration: none;

            &:hover {
              border-left: $search-desktop-categorylist-item-hover-border;
              font-size: $search-desktop-categorylist-item-hover-font-size;
              font-weight: $search-desktop-categorylist-item-hover-font-weight;
              color: $search-desktop-categorylist-item-hover-color;
            }
          }

          &.active {
            > a {

              &,
              &:hover {
                border-left: $search-desktop-categorylist-item-active-border;
                font-size: $search-desktop-categorylist-item-active-font-size;
                font-weight: $search-desktop-categorylist-item-active-font-weight;
                color: $search-desktop-categorylist-item-active-color;
              }
            }

            > a:is(:last-child) {
              border-left: $search-desktop-categorylist-item-active-border;
            }
          }

          ul {
            margin-left: $search-desktop-categorylist-sub-item-margin;
            font-size: $search-desktop-categorylist-sub-item-font-size;
          }
        }
      }
    }

    .search-introcontent {
      margin-bottom: $search-desktop-introcontent-gap;
    }

    .search-results {
      margin-top: $search-desktop-results-gap;
      margin-bottom: $search-desktop-results-gap;

      .results-noresuls {
        padding: $search-desktop-results-noresults-padding-y $search-desktop-results-noresults-padding-x;
        text-align: $search-desktop-results-noresults-text-align;
      }
    }

    .search-loadmore {
      margin-bottom: $search-desktop-loadmore-gap;
    }

    .search-scrolltop {
      margin-bottom: $search-desktop-scrolltop-gap;
      text-align: right;
    }

    .search-outrocontent {
      margin-bottom: $search-desktop-outrocontent-gap;

      .category-content {

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        .h1,
        .h2,
        .h3,
        .h4,
        .h5,
        .h6 {
          font-weight: $search-outrocontent-font-weight;
          font-size: inherit;
        }

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
