<template>
  <div v-if="$root.env.isDevelopment">
    <b-alert show variant="danger">
      <h6>Unknown dynamic Component in <code class="text-danger">&lt;{{ parent.$options.name }}/&gt;</code></h6>

      <p><strong>Properties</strong></p>
      <pre class="text-inherit">{{ $props }}</pre>
      <p><strong>Attributes</strong></p>
      <pre class="text-inherit">{{ $attrs }}</pre>
      <p><strong>Listeners</strong></p>
      <pre class="text-inherit">{{ $listeners }}</pre>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'UnknownDynamicComponent',
  computed: {
    parent () {
      let parent = this.$parent

      while (!parent.$options.name && parent !== this.$root) {
        parent = parent.$parent
      }

      return parent
    }
  }
}
</script>
