<template>
  <div :class="['search-filter', 'filter-multiselect', `type-${filter.type}`, { 'is-expanded': expanded }]">
    <b-button class="filter-toggle" variant="link" block @click="toggle()">
      {{ filter.displayName }} <lazy-icon icon="caret-down" scale="0.6"/>
    </b-button>

    <transition-expand name="filterexpander" :expanded="expanded">
      <div v-show="expanded" class="filter-content">
        <div>
          <vue-scroll :ops="scrollOptions" ref="scrollbar">
            <div class="filter-controls">
              <div
                v-for="control in filter.controls"
                :key="control.id"
                class="filter-control"
              >
                <b-form-checkbox
                  :id="control.id"
                  v-model="control.value"
                  @change="$value => updateControl(control, $value)"
                >
                  <component :is="control.value ? 'strong' : 'span'">{{ control.text }}</component><span class="control-count">{{ $n(control.count, 'decimal') }}</span>
                </b-form-checkbox>
              </div>
            </div>
          </vue-scroll>
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import SCSS_VARIABLES from '@/assets/js/modules/scss-variables'

import VueScroll from 'vuescroll/dist/vuescroll-native'
import MixinSearchFilter from '@/components/mixins/SearchFilter'
import TransitionExpand from '@/components/private/TransitionExpand'

export default {
  name: 'SearchFilterMultiselect',
  mixins: [MixinSearchFilter],
  components: {
    VueScroll,
    TransitionExpand
  },
  data () {
    return {
      // https://vuescrolljs.yvescoding.org/guide/configuration.html
      // https://github.com/YvesCoding/vuescroll/blob/dev/src/shared/global-config.js
      scrollOptions: {
        sizeStrategy: 'percent',
        detectResize: true,
        locking: true,
        maxHeight: undefined,
        scrollPanel: {
          initialScrollY: false,
          initialScrollX: false,
          scrollingY: true,
          scrollingX: false,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: 'right',
          maxHeight: this.$root.isDesktop ? 312 : undefined,
          maxWidth: undefined
        },
        rail: {
          background: SCSS_VARIABLES.colors.theme.control,
          border: 'none',
          opacity: 1,
          size: '6px',
          specifyBorderRadius: false,
          gutterOfEnds: '0px',
          gutterOfSide: '0px',
          keepShow: false
        },
        bar: {
          disable: false,
          background: SCSS_VARIABLES.colors.theme.dark,
          opacity: 1,
          size: '6px',
          showDelay: 0,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: 0,
          onlyShowBarOnScroll: false,
          keepShow: true
        },
        scrollButton: {
          enable: false,
          background: SCSS_VARIABLES.colors.theme.dark,
          opacity: 1,
          step: 50,
          mousedownStep: 30
        },
        vuescroll: {
          wheelScrollDuration: 500,
          wheelDirectionReverse: false,
          checkShifKey: true
        }
      }
    }
  },
  watch: {
    '$root.mobilefirstBreakpoint' () {
      this.scrollOptions.scrollPanel.maxHeight = this.$root.isDesktop ? 312 : undefined
      this.$refs.scrollbar.refresh()
    }
  }
}
</script>

<style lang="scss">
$searchfilter-multiselect-control-border-width: 1px !default;
$searchfilter-multiselect-control-border-color: $control !default;

.search-filter.filter-multiselect {
  .custom-checkbox {
    .custom-control-input {
      &:not(:checked) {
        ~ .custom-control-label {
          &:before {
            border-width: $searchfilter-multiselect-control-border-width;
            border-color: $searchfilter-multiselect-control-border-color;
          }
        }
      }
    }
  }
}
</style>
