<script>
import { COMPONENT_SEARCH_FILTER_TYPES } from '@/constants'

export default {
  props: {
    filter: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },
  data () {
    const filterIsSet = this.$store.getters['search/getActiveFilters'].find(f => f.id === this.filter.id) !== undefined
    const filterOpenByStorage = JSON.parse(window.sessionStorage.getItem(this.filter.id))
    const initialOpenFilterKeys = ['material', 'farbe', 'colorgestell', 'colorsitz', 'preis', 'model']

    return {
      expanded: filterIsSet || (filterOpenByStorage !== null ? filterOpenByStorage : initialOpenFilterKeys.includes(this.filter.key))
    }
  },
  computed: {
    activeFilters () {
      return this.$store.getters['search/getActiveFilters']
    },
    filterActiveCount () {
      return this.filter.controls.filter(c => c.value !== COMPONENT_SEARCH_FILTER_TYPES[this.filter.type].controls[c.type].emptyValue).length
    },
    filterAllActive () {
      return this.filter.controls.every(c => c.value !== COMPONENT_SEARCH_FILTER_TYPES[this.filter.type].controls[c.type].emptyValue)
    },
    filterNoneActive () {
      return this.filter.controls.every(c => c.value === COMPONENT_SEARCH_FILTER_TYPES[this.filter.type].controls[c.type].emptyValue)
    }
  },
  methods: {
    toggle (state = null) {
      this.expanded = state !== null ? state : !this.expanded
      window.sessionStorage.setItem(this.filter.id, this.expanded)
      this.$emit(`filter:${this.expanded ? 'expanding' : 'collapsing'}`)
    },
    setControls (value = null) {
      if (value !== null) {
        this.filter.controls.forEach(c => { c.value = value })
        this.$emit('filter:change', this.filter)
      }
    },
    resetControls () {
      this.filter.controls.forEach(c => { c.value = COMPONENT_SEARCH_FILTER_TYPES[this.filter.type].controls[c.type].emptyValue })
      this.$emit('filter:change', this.filter)
    },
    updateControl (control, value) {
      (this.filter.controls.find(c => c.id === control.id) || {}).value = value
      this.$emit('filter:change', this.filter)
    }
  },
  created () {
    this.$on('filter:change', () => {
      this.$store.dispatch('search/setFilters')
    })
  }
}
</script>

<style lang="scss">
// mobile

$searchfilter-controls-control-gap: $spacer * 0.5 !default;
$searchfilter-controls-control-count-gap: $spacer !default;
$searchfilter-controls-control-count-font-size: $font-size-sm !default;
$searchfilter-controls-control-count-color: $dark !default;

// desktop

$searchfilter-desktop: $desktop-breakpoint !default;

$searchfilter-desktop-gap: $spacer * 0.5 !default;
$searchfilter-desktop-bg: $beige !default;
$searchfilter-desktop-border-radius: $border-radius !default;

$searchfilter-desktop-toggle-padding-y: $spacer * 0.75 !default;
$searchfilter-desktop-toggle-padding-x: $searchfilter-desktop-toggle-padding-y !default;
$searchfilter-desktop-toggle-font-size: null !default;
$searchfilter-desktop-toggle-font-weight: $font-weight-bold !default;
$searchfilter-desktop-toggle-color: inherit !default;
$searchfilter-desktop-toggle-icon-transition: $transition-base !default;

$searchfilter-desktop-content-padding-y: $searchfilter-desktop-toggle-padding-y !default;
$searchfilter-desktop-content-padding-x: $searchfilter-desktop-toggle-padding-x !default;

$searchfilter-desktop-controls-control-gap: $searchfilter-controls-control-gap !default;
$searchfilter-desktop-controls-control-count-gap: $searchfilter-controls-control-count-gap !default;
$searchfilter-desktop-controls-control-count-font-size: $searchfilter-controls-control-count-font-size !default;
$searchfilter-desktop-controls-control-count-color: $searchfilter-controls-control-count-color !default;

$searchfilter-desktop-expander-transition-in: height ease-out 300ms !default;
$searchfilter-desktop-expander-transition-out: height ease-out 150ms !default;

.search-filter {
  @include media-breakpoint-down(get-breakpoint-key($searchfilter-desktop, -1)) {
    height: 100%;

    .filter-toggle {
      display: none;
    }

    .filter-content {
      display: block!important;
      height: 100%;

      > div {
        height: 100%;
      }

      .filter-controls {
        .filter-control {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: $searchfilter-controls-control-gap;

          .control-count {
            display: inline-block;
            margin-left: $searchfilter-controls-control-count-gap;
            font-size: $searchfilter-controls-control-count-font-size;
            color: $searchfilter-controls-control-count-color;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  @include media-breakpoint-up($searchfilter-desktop) {
    margin-bottom: $searchfilter-desktop-gap;
    background-color: $searchfilter-desktop-bg;
    border-radius: $searchfilter-desktop-border-radius;

    .filter-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: $searchfilter-desktop-toggle-padding-y $searchfilter-desktop-toggle-padding-x;
      background: none;
      border: 0 none;
      box-shadow: none;
      font-size: $searchfilter-desktop-toggle-font-size;
      font-weight: $searchfilter-desktop-toggle-font-weight;
      color: $searchfilter-desktop-toggle-color;
      text-decoration: none;

      .bi {
        transition: $searchfilter-desktop-toggle-icon-transition;
      }
    }

    .filter-content {
      padding: 0 $searchfilter-desktop-content-padding-x $searchfilter-desktop-content-padding-y;

      .filter-controls {
        .filter-control {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: $searchfilter-desktop-controls-control-gap;

          .control-count {
            display: inline-block;
            margin-left: $searchfilter-desktop-controls-control-count-gap;
            font-size: $searchfilter-desktop-controls-control-count-font-size;
            color: $searchfilter-desktop-controls-control-count-color;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    .filterexpander-enter-active {
      overflow: hidden;
      transition: $searchfilter-desktop-expander-transition-in;
    }

    .filterexpander-leave-active {
      overflow: hidden;
      transition: $searchfilter-desktop-expander-transition-out;
    }

    &.is-expanded {
      .filter-toggle {
        .bi {
          transform: scaleY(-1);
        }
      }
    }
  }
}
</style>
