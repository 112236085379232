<template>
  <div :class="['sticky', { 'is-sticky': isSticky }]">
    <div class="sticky-placeholder" :style="placeholderStyles"></div>
    <div class="sticky-container" ref="container" :style="containerStyles">
      <div class="sticky-container-inner" :style="containerInnerStyles">
        <slot name="default"/>
      </div>
    </div>
  </div>
</template>

<script>
import { getPath } from '@/assets/js/helper/dom'

export default {
  name: 'Sticky',
  props: {
    placement: {
      type: String,
      default: 'top'
    },
    offsetTop: {
      type: Number,
      default: 0
    },
    offsetBottom: {
      type: Number,
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isSticky: false,
      lastState: false,
      wrapperBounds: {
        top: 0,
        bottom: 0
      },
      elementBounds: {
        top: 0,
        bottom: 0,
        width: 0,
        height: 0
      }
    }
  },
  computed: {
    wrapper () {
      return getPath(this.$el || document.body).find(el => el.hasAttribute('sticky-wrapper')) || this.$el
    },
    placeholderStyles () {
      if (!this.isSticky) return null

      return {
        width: `${this.elementBounds.width}px`,
        height: `${this.elementBounds.height}px`
      }
    },
    containerStyles () {
      if (!this.isSticky) return null

      return {
        top: this.placement === 'top' ? `${Math.min(this.offsetTop, this.wrapperBounds.bottom - this.elementBounds.height)}px` : null,
        bottom: this.placement === 'bottom' ? `${Math.min(this.offsetBottom, window.innerHeight - this.wrapperBounds.top - this.elementBounds.height)}px` : null,
        left: 0,
        right: 0
      }
    },
    containerInnerStyles () {
      if (!this.isSticky) return null

      return {
        marginLeft: `${this.elementBounds.left}px`,
        marginRight: `${this.elementBounds.right - this.elementBounds.width}px`,
        width: `${this.elementBounds.width}px`,
        height: `${this.elementBounds.height}px`
      }
    }
  },
  methods: {
    setBounds () {
      if (!this.disabled) {
        this.wrapperBounds.width = 'auto'
        this.wrapperBounds.height = 'auto'
        this.elementBounds.width = 'auto'
        this.elementBounds.height = 'auto'

        this.$nextTick(() => {
          const wrapperBounds = this.wrapper.getBoundingClientRect()
          const elementBounds = this.$el.getBoundingClientRect()

          this.wrapperBounds.top = wrapperBounds.top
          this.wrapperBounds.bottom = wrapperBounds.bottom

          this.elementBounds.top = elementBounds.top
          this.elementBounds.bottom = elementBounds.bottom
          this.elementBounds.left = elementBounds.left
          this.elementBounds.right = elementBounds.right
          this.elementBounds.width = elementBounds.width
          this.elementBounds.height = elementBounds.height

          this.setSticky()
        })
      } else {
        this.setSticky()
      }
    },
    setSticky () {
      this.lastState = this.isSticky

      if (!this.disabled) {
        this.isSticky = (this.placement === 'top' && this.elementBounds.top <= this.offsetTop) || (this.placement === 'bottom' && this.elementBounds.bottom - this.offsetBottom >= window.innerHeight)

        if (this.isSticky !== this.lastState) {
          this.$emit('stickiness', this.isSticky)
        }
      } else {
        this.isSticky = false
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.setBounds()
      window.addEventListener('resize', this.setBounds)
      window.addEventListener('scroll', this.setBounds)
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.setBounds)
    window.removeEventListener('scroll', this.setBounds)
  }
}
</script>

<style lang="scss">
  .sticky {
    .sticky-placeholder {
      display: none;
    }

    &.is-sticky {
      .sticky-placeholder {
        display: block;
      }

      .sticky-container {
        position: fixed;
        z-index: $zindex-sticky;
      }
    }
  }
</style>
