import SCSS_VARIABLES from '@/assets/js/modules/scss-variables'

// based on https://gist.github.com/oriadam/396a4beaaad465ca921618f2f2444d49
export function toRgba (color, options = {}) {
  const o = Object.assign({
    omitAlpha: false,
    asArray: false
  }, options || {})

  if (!color) return
  if (color.toLowerCase() === 'transparent') return [0, 0, 0, 0]

  if (/^#/.test(color)) {
    // convert #RGB and #RGBA to #RRGGBB and #RRGGBBAA
    // note: the length of the color with # is 7 ;)
    if (color.length < 7) {
      color = `#${color[1] + color[1]}${color[2] + color[2]}${color[3] + color[3]}${(color[4] || '') + (color[4] || '')}`
    }

    const rgba = [
      parseInt(color.substr(1, 2), 16),
      parseInt(color.substr(3, 2), 16),
      parseInt(color.substr(5, 2), 16),
      Math.max(0, Math.min(1, color.length > 7 ? parseInt(color.substr(7, 2), 16) / 255 : 1))
    ]

    return returnValue(rgba)
  }

  // convert named colors to rgb(a)
  if (!/^rgb/.test(color)) {
    const colorConversionElement = document.body.appendChild(document.createElement('colorconversion'))

    colorConversionElement.style.setProperty('color', color, 'important')

    if (!colorConversionElement.style.color) {
      console.error(`ERROR: ColorConverter "toRgba", convert named color faild to convert "${color}" to an rgba color!`)
      return returnValue([0, 0, 0, 1])
    }

    color = window.getComputedStyle(colorConversionElement).color
    document.body.removeChild(colorConversionElement)
  }

  if (/^rgb/.test(color)) {
    const rgba = color.match(/[.\d]+/g)
      .concat(1)
      .slice(0, 4)
      .map((m, i) => i === 3 ? Math.max(0, Math.min(1, Number(m))) : Number(m))

    return returnValue(rgba)
  }

  console.error(`ERROR: ColorConverter faild to convert "${color}" to an rgba color!`)
  return returnValue([0, 0, 0, 1])

  function returnValue (rgba) {
    if (o.omitAlpha && rgba[3] === 1) {
      const rgb = rgba.slice(0, 3)
      return o.asArray ? rgb : `rgb(${rgb.join(', ')})`
    }

    return o.asArray ? rgba : `rgba(${rgba.join(', ')})`
  }
}

export function toHex (color, options = {}) {
  const o = Object.assign({
    omitAlpha: true,
    asArray: false
  }, options || {})

  const rgba = toRgba(color, { asArray: true })

  const hexa = [
    `0${rgba[0].toString(16)}`.slice(-2),
    `0${rgba[1].toString(16)}`.slice(-2),
    `0${rgba[2].toString(16)}`.slice(-2),
    `0${Math.round(Math.max(0, Math.min(255, rgba[3] * 255))).toString(16)}`.slice(-2)
  ]

  if (o.omitAlpha && hexa[3] === 'ff') {
    const hex = hexa.slice(0, 3)
    return o.asArray ? hex : `#${hex.join('')}`
  }

  return o.asArray ? hexa : `#${hexa.join('')}`
}

// based on http://stackoverflow.com/a/3943023/112731
export function contrast (color, options = {}) {
  const o = Object.assign({
    threshold: Number(SCSS_VARIABLES.vars['color-contrast-threshold']),
    dark: SCSS_VARIABLES.vars['body-color'],
    light: SCSS_VARIABLES.vars['body-contrast-color']
  }, options || {})

  const rgba = toRgba(color, { omitAlpha: true, asArray: true })

  const r = rgba[0] * 0.299
  const g = rgba[1] * 0.587
  const b = rgba[2] * 0.114

  return r + g + b > o.threshold ? o.dark : o.light
}
