<template>
  <transition v-if="filters.length" name="menu">
    <div v-if="$root.isDesktop || state" class="search-filter-menu">
      <div class="menu-header">
        <h6 class="menu-title">{{ $t(`${tPath}.title`) }}</h6>

        <b-button variant="default" class="menu-toggle" @click="$root.$emit('searchfiltermenu:toggle', false)"><lazy-icon icon="remove"/></b-button>
      </div>

      <div class="menu-body">
        <nav class="menu-navigation">
          <div v-if="activeFilterIds.length">
            <b-button class="filter-all-resetter" variant="shapeless" @click="resetAllFilters">{{ $t(`${tPath}.resetall`) }} <lazy-icon icon="remove"/></b-button>
          </div>

          <ul class="navigation-list">
            <li v-for="{ id, displayName } in filters" :key="id">
              <a class="list-item" href="#" @click="setContentId(id)">{{ displayName }}<lazy-icon icon="caret-right" scale="0.75"/></a>
            </li>
          </ul>
        </nav>

        <transition-group name="menucontent" tag="div" :class="['menu-content', { 'has-content': contentId }]">
          <div
            v-for="filter in filters"
            :key="filter.id"
            v-show="$root.isDesktop || filter.id === contentId"
            class="content-item"
          >
            <div class="content-header">
              <a href="#" class="content-close" @click="setContentId(null)"><lazy-icon icon="caret-left" scale="0.75"/> {{ $t(`${tPath}.menu.close`) }}</a>
            </div>

            <div class="content-body">
              <component :is="filter.component" :filter="filter"/>
            </div>

            <div class="content-footer">
              <b-button v-if="activeFilterIds.includes(filter.id)" variant="outline-control" block @click="resetFilter(filter.id)">{{ $t(`${tPath}.menu.resetfilter`) }}</b-button>
              <b-button variant="primary" block @click="$root.$emit('searchfiltermenu:toggle', false)">{{ $t(`${tPath}.menu.show.count${['0', '1', 'x'][Math.min(statistics.total.items, 2)]}`, { count: statistics.total.items }) }}</b-button>
            </div>
          </div>
        </transition-group>
      </div>
    </div>
  </transition>
</template>

<script>
import SearchFilterText from '@/components/private/SearchFilterText'
import SearchFilterMultiselect from '@/components/private/SearchFilterMultiselect'
import SearchFilterRange from '@/components/private/SearchFilterRange'
import SearchFilterColors from '@/components/private/SearchFilterColors'
import SearchFilterHidden from '@/components/private/SearchFilterHidden'
import SearchFilterUnknown from '@/components/private/UnknownDynamicComponent'

export default {
  name: 'SearchFilterMenu',
  components: {
    SearchFilterText,
    SearchFilterMultiselect,
    SearchFilterRange,
    SearchFilterColors,
    SearchFilterHidden,
    SearchFilterUnknown
  },
  data () {
    return {
      state: false,
      contentId: null
    }
  },
  computed: {
    statistics () {
      return this.$store.getters['search/statistics']
    },
    filters () {
      return this.$store.getters['search/filters']
    },
    activeFilterIds () {
      return this.$store.getters['search/getActiveFilters'].map(f => f.id)
    }
  },
  methods: {
    setContentId (filterId = null) {
      this.contentId = filterId
    },
    resetAllFilters () {
      this.$store.dispatch('search/resetFilters')
      this.setContentId(null)
    },
    resetFilter (filterId) {
      this.$store.dispatch('search/resetFilter', { filterId })
    }
  },
  created () {
    this.$root.$on('searchfiltermenu:toggle', state => {
      this.state = state
      if (!this.state) this.contentId = null
    })
  },
  watch: {
    state (state) {
      this.$root.$emit('scrollbar:toggle', { key: this.$options.name, state: this.state, from: 0, till: this.$root.md.value })
    }
  }
}
</script>

<style lang="scss">
// mobile
$searchfiltermenu-transition-speed-in: 200ms !default;
$searchfiltermenu-transition-speed-out: 150ms !default;

$searchfiltermenu-open-transition-in: transform ease-out $searchfiltermenu-transition-speed-in !default;
$searchfiltermenu-open-transition-out: transform ease-out $searchfiltermenu-transition-speed-out !default;

$searchfiltermenu-bg: $beige !default;

$searchfiltermenu-header-padding-y: 0 !default;
$searchfiltermenu-header-padding-x: $grid-gutter-width * 0.5 !default;
$searchfiltermenu-header-bg: $white !default;
$searchfiltermenu-header-box-shadow: map-get($shadows, 'header') !default;

$searchfiltermenu-menu-toggle-padding-y: $spacer * 0.8125 !default;
$searchfiltermenu-menu-toggle-padding-x: $spacer !default;

$searchfiltermenu-body-padding-y: $grid-gutter-width * 0.5 !default;
$searchfiltermenu-body-padding-x: $grid-gutter-width * 0.5 !default;

$searchfiltermenu-navigation-allresetter-gap: $spacer * 0.75 !default;

$searchfiltermenu-navigation-list-item-padding-y: $spacer * 0.5 !default;
$searchfiltermenu-navigation-list-item-padding-x: 0 !default;
$searchfiltermenu-navigation-list-item-border: map-get($borders, 'base') !default;
$searchfiltermenu-navigation-list-item-font-size: inherit !default;
$searchfiltermenu-navigation-list-item-color: inherit !default;

$searchfiltermenu-content-open-transition-in: transform cubic-bezier(.5,0,.5,.5) $searchfiltermenu-transition-speed-in !default;
$searchfiltermenu-content-open-transition-out: transform cubic-bezier(.5,0,.5,.5) $searchfiltermenu-transition-speed-out !default;

$searchfiltermenu-content-header-gap: $spacer !default;
$searchfiltermenu-content-footer-gap: $searchfiltermenu-content-header-gap !default;

// desktop

$searchfiltermenu-desktop: $desktop-breakpoint !default;

.search-filter-menu {
  @include media-breakpoint-down(get-breakpoint-key($searchfiltermenu-desktop, -1)) {
    display: flex;
    flex-direction: column;
    position: fixed;
    inset: 0;
    z-index: $zindex-fixed;
    background-color: $searchfiltermenu-bg;

    .menu-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 0 0 auto;
      padding: $searchfiltermenu-header-padding-y $searchfiltermenu-header-padding-x;
      background-color: $searchfiltermenu-header-bg;
      box-shadow: $searchfiltermenu-header-box-shadow;

      .menu-title {
        margin: 0;
      }

      .menu-toggle {
        padding: $searchfiltermenu-menu-toggle-padding-y $searchfiltermenu-menu-toggle-padding-x;
        padding-right: 0;
        background: none;
        border: 0 none;
      }
    }

    .menu-body {
      flex: 0 1 100%;
      position: relative;
      overflow: hidden;
      padding: $searchfiltermenu-body-padding-y $searchfiltermenu-body-padding-x;

      .menu-navigation {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: 100%;

        .filter-all-resetter {
          margin-bottom: $searchfiltermenu-navigation-allresetter-gap;
        }

        .navigation-list {
          @include list-unstyled();
          flex: 0 1 100%;
          overflow: hidden;
          overflow-y: auto;

          .list-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: $searchfiltermenu-navigation-list-item-padding-y $searchfiltermenu-navigation-list-item-padding-x;
            border-top: $searchfiltermenu-navigation-list-item-border;
            font-size: $searchfiltermenu-navigation-list-item-font-size;
            color: $searchfiltermenu-navigation-list-item-color;
            text-decoration: none;
          }

          > li:last-child {
            .list-item {
              border-bottom: $searchfiltermenu-navigation-list-item-border;
            }
          }
        }
      }

      .menu-content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 100%;
        right: -100%;
        transition: all 0ms linear nth($searchfiltermenu-content-open-transition-out, 3);

        .content-item {
          display: flex;
          flex-direction: column;
          overflow: hidden;
          padding: $searchfiltermenu-body-padding-y $searchfiltermenu-body-padding-x;
          height: 100%;
          background-color: $searchfiltermenu-bg;

          &.menucontent-enter,
          &.menucontent-leave-to {
            transform: translate3d(100%, 0, 0);
          }

          &.menucontent-enter-to,
          &.menucontent-leave {
            transform: translate3d(0%, 0, 0);
          }

          &.menucontent-enter-active {
            transition: $searchfiltermenu-content-open-transition-in;
          }

          &.menucontent-leave-active {
            transition: $searchfiltermenu-content-open-transition-out;
          }

          .content-header {
            margin-bottom: $searchfiltermenu-content-header-gap;

            .content-close {
              color: inherit;
              text-decoration: none;
            }
          }

          .content-body {
            flex: 0 1 100%;
            overflow: hidden;

            .search-filter {
              height: 100%;
            }
          }

          .content-footer {
            margin-top: $searchfiltermenu-content-footer-gap;
          }
        }

        &.has-content {
          inset: 0;
          transition: none;
        }
      }
    }

    &.menu-enter,
    &.menu-leave-to {
      transform: translate3d(0, 100%, 0);
    }

    &.menu-enter-to,
    &.menu-leave {
      transform: translate3d(0, 0%, 0);
    }

    &.menu-enter-active {
      transition: $searchfiltermenu-open-transition-in;
    }

    &.menu-leave-active {
      transition: $searchfiltermenu-open-transition-out;
    }
  }

  @include media-breakpoint-up($searchfiltermenu-desktop) {
    .menu-header {
      display: none;
    }

    .menu-body {
      .menu-navigation {
        display: none;
      }

      .menu-content {
        .content-header {
          display: none;
        }

        .content-footer {
          display: none;
        }
      }
    }
  }
}
</style>
