<template>
  <div :class="['search-filter', 'filter-range', `type-${filter.type}`, { 'is-expanded': expanded }]">
    <b-button class="filter-toggle" variant="link" block @click="toggle()">
      {{ filter.displayName }} <lazy-icon icon="caret-down" scale="0.6"/>
    </b-button>

    <transition-expand name="filterexpander" :expanded="expanded">
      <div v-show="expanded" class="filter-content">
        <div class="filter-controls">
          <div
            v-for="control in controls"
            :key="control.id"
            class="filter-control"
          >
            <b-input-group size="xs" :append="control.unit">
              <b-form-input
                :id="control.id"
                v-model="control.value"
                lazyFormatter
                @keydown="$event => controlInputManager($event, control)"
                @change="controlChangeManager(control)"
              />
            </b-input-group>
          </div>
        </div>

        <div class="filter-slider">
          <slider
            v-model="slider.value"
            v-bind="slider"
            @update="sliderUpdateManager"
            @change="sliderChangeManager"
          />
        </div>
      </div>
    </transition-expand>
  </div>
</template>

<script>
import MixinSearchFilter from '@/components/mixins/SearchFilter'

import { copy } from '@/assets/js/helper/array'

import TransitionExpand from '@/components/private/TransitionExpand'
// for further informations visit: https://github.com/vueform/slider
import Slider from '@vueform/slider/dist/slider.vue2.js'

export default {
  name: 'SearchFilterRange',
  mixins: [MixinSearchFilter],
  components: {
    TransitionExpand,
    Slider
  },
  data () {
    const controlsClone = copy(this.filter.controls || [])

    return {
      slider: this.getSliderOptions(controlsClone),
      controls: controlsClone
    }
  },
  computed: {
    numberSeparators () {
      return this.$store.getters['gui/number:get']
    }
  },
  methods: {
    getSliderOptions (controls = []) {
      return {
        value: controls.map(c => Number(c.value)),
        min: controls[0].minValue,
        max: controls[1].maxValue,
        step: 0.1,
        lazy: false,
        tooltips: false,
        options: {
          margin: 0.1
        }
      }
    },
    controlInputManager (event, control = {}) {
      const stringValue = String(control.value)

      // prevent non allowed events
      if (!/(\d|\.|,|ArrowLeft|ArrowRight|Backspace|Delete)/.test(event.key) && !(event.ctrlKey && event.key === 'a')) {
        event.preventDefault()
        return
      }

      // prevent second separator
      if (/\./.test(stringValue) && (event.key === '.' || event.key === ',')) {
        event.preventDefault()
        return
      }

      // replace ',' with JS decimal separator '.'
      if (event.key === ',') {
        event.preventDefault()

        const controlElement = this.$el.querySelector(`input[id="${control.id}"]`)
        const cursorPosition = controlElement ? controlElement.selectionStart : stringValue.length

        control.value = ''.concat(stringValue.slice(0, cursorPosition), '.', stringValue.slice(cursorPosition))
        if (controlElement) controlElement.setSelectionRange(cursorPosition, cursorPosition)
      }
    },
    controlChangeManager (control = {}) {
      const vIndex = ['minValue', 'maxValue'].findIndex(key => key === control.key)
      const numberValue = Number(control.value === '' ? control.baseValue : String(control.value).replace(new RegExp(this.numberSeparators.thousandSeparator, 'g'), '').replace(this.numberSeparators.decimalSeparator, '.'))

      this.$set(this.slider.value, vIndex, numberValue)
      this.controls[vIndex].value = numberValue

      this.emitFilterChange()
    },
    sliderUpdateManager (value) {
      value.forEach((v, vIndex) => {
        this.controls[vIndex].value = v
      })
    },
    sliderChangeManager (value) {
      value.forEach((v, vIndex) => {
        this.controls[vIndex].value = v
      })

      this.emitFilterChange()
    },
    emitFilterChange () {
      this.controls
        .forEach((c, cIndex) => {
          this.filter.controls[cIndex].value = c.value
        })

      this.$emit('filter:change', this.filter)
    }
  },
  watch: {
    '$props.filter': {
      deep: true,
      handler (filter = {}) {
        const controlsClone = copy(this.filter.controls || [])

        this.slider = this.getSliderOptions(controlsClone)
        this.controls = controlsClone
      }
    }
  }
}
</script>

<style lang="scss">
$searchfilter-range-slider-gap: $spacer !default;

$searchfilter-range-slider-bar-size: 6px !default;
$searchfilter-range-slider-bar-bg: $gray-300 !default;
$searchfilter-range-slider-bar-border-radius: $searchfilter-range-slider-bar-size!default;
$searchfilter-range-slider-bar-active-bg: $black !default;
$searchfilter-range-slider-bar-active-disabled-bg: $input-disabled-bg !default;

$searchfilter-range-slider-handle-size: $spacer * 2 !default;
$searchfilter-range-slider-handle-bg: $white !default;
$searchfilter-range-slider-handle-border: set-nth(map-get($borders, 'base'), 3, $dark) !default;
$searchfilter-range-slider-handle-border-radius: $searchfilter-range-slider-handle-size !default;

$searchfilter-range-slider-handle-icon-color: $dark !default;
$searchfilter-range-slider-handle-0-icon: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='#{$searchfilter-range-slider-handle-icon-color}' d='M4.2,15.2C4,15,4,14.8,4,14.7s0-0.3,0.2-0.5L10.4,8L4.2,1.8C4.1,1.6,4.1,1.4,4.1,1.3s0-0.3,0.1-0.4s0.2-0.2,0.4-0.2c0.2,0,0.4,0,0.6,0.2l6.4,6.4C11.9,7.5,12,7.9,12,8.1c0,0.2-0.1,0.6-0.3,0.8l-6.6,6.5C4.8,15.5,4.5,15.4,4.2,15.2z'/></svg>")) !default;
$searchfilter-range-slider-handle-1-icon: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path fill='#{$searchfilter-range-slider-handle-icon-color}' d='M11.8,0.8C12,1,12,1.2,12,1.3s0,0.3-0.2,0.5L5.6,8l6.2,6.2c0.2,0.2,0.2,0.4,0.2,0.5s0,0.3-0.1,0.4c-0.1,0.1-0.2,0.2-0.4,0.2s-0.4,0-0.6-0.2L4.4,8.8C4.2,8.6,4.1,8.2,4.1,8s0.1-0.6,0.3-0.8L11,0.7C11.2,0.5,11.5,0.6,11.8,0.8z'/></svg>")) !default;

.search-filter.filter-range {
  .filter-content {
    .filter-controls {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .filter-control {
        .form-control {
          padding-left: 0.5rem;
          padding-right: 0.5rem;
        }

        + .filter-control {
          margin-left: $form-grid-gutter-width;
        }
      }
    }

    .filter-slider {
      margin-top: $searchfilter-range-slider-gap;
      margin-bottom: $searchfilter-range-slider-gap;

      --slider-height: #{$searchfilter-range-slider-bar-size};
      // --slider-vertical-height:300px;
      --slider-bg: #{$searchfilter-range-slider-bar-bg};
      --slider-radius: #{$searchfilter-range-slider-bar-border-radius};

      --slider-connect-bg: #{$searchfilter-range-slider-bar-active-bg};
      --slider-connect-bg-disabled: #{$searchfilter-range-slider-bar-active-disabled-bg};

      --slider-handle-width: #{$searchfilter-range-slider-handle-size};
      --slider-handle-height: #{$searchfilter-range-slider-handle-size};
      --slider-handle-bg: #{$searchfilter-range-slider-handle-bg};
      --slider-handle-border: #{$searchfilter-range-slider-handle-border};
      --slider-handle-radius: #{$searchfilter-range-slider-handle-border-radius};
      --slider-handle-shadow: none;
      --slider-handle-shadow-active: none;
      --slider-handle-ring-width: 0;
      --slider-handle-ring-color: transparent;

      // --slider-tooltip-font-size: 0.875rem;
      // --slider-tooltip-line-height: 1.25rem;
      // --slider-tooltip-font-weight: 600;
      // --slider-tooltip-min-width: 20px;
      // --slider-tooltip-bg: #10B981;
      // --slider-tooltip-bg-disabled: #9CA3AF;
      // --slider-tooltip-color: #fff;
      // --slider-tooltip-radius: 5px;
      // --slider-tooltip-py: 2px;
      // --slider-tooltip-px: 6px;
      // --slider-tooltip-arrow-size: 5px;
      // --slider-tooltip-distance: 3px;

      @import '~@vueform/slider/themes/default.scss';

      .slider-target {
        .slider-base {
          .slider-connects {
            overflow: visible;
            margin-left: $searchfilter-range-slider-handle-size;
            margin-right: $searchfilter-range-slider-handle-size;
            width: calc(100% - $searchfilter-range-slider-handle-size * 2);

            .slider-connect {
              &:before,
              &:after {
                display: block;
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                width: $searchfilter-range-slider-handle-size * 0.8;
                background-color: $searchfilter-range-slider-bar-active-bg;
              }

              &:before {
                left: 100%;
              }

              &:after {
                right: 100%;
              }
            }
          }

          .slider-origin {
            margin-left: $searchfilter-range-slider-handle-size;
            margin-right: $searchfilter-range-slider-handle-size;
            width: calc(100% - $searchfilter-range-slider-handle-size * 2);

            .slider-handle {
              background: no-repeat center center var(--slider-handle-bg);
              background-size: 50%;

              &[data-handle="0"] {
                right: 0;
                background-image: $searchfilter-range-slider-handle-0-icon;
              }

              &[data-handle="1"] {
                right: $searchfilter-range-slider-handle-size * -1;
                background-image: $searchfilter-range-slider-handle-1-icon;
              }
            }
          }
        }
      }
    }
  }
}
</style>
