<template>
  <div v-if="sortings.length" class="search-sorting">
    <label :for="dropdownId" class="sorting-label">{{ $t(`${tPath}.label`) }}</label>

    <b-dropdown
      :id="dropdownId"
      variant="outline-mud"
      size="xs"
      right
      class="sorting-dropdown"
    >
      <template #button-content>
        <span class="dropdown-text"> {{ activeSorting.displayName }}</span>
      </template>

      <b-dropdown-item
        v-for="sorting in sortings"
        :key="sorting.id"
        :active="sorting.active"
        @click="setActiveSorting(sorting.id)"
      >{{ sorting.displayName }}</b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
export default {
  name: 'Search.Sorting',
  data () {
    return {
      dropdownId: `SearchSorting_${this._uid}`
    }
  },
  computed: {
    is () {
      return this.$store.getters['search/is']
    },
    sortings () {
      return this.$store.getters['search/sortings']
    },
    activeSorting () {
      return this.$store.getters['search/getActiveSorting'] || {}
    }
  },
  methods: {
    setActiveSorting (sortingId) {
      this.$store.dispatch('search/setActiveSorting', { sortingId })
    }
  }
}
</script>

<style lang="scss">
// mobile

// tablet

$searchsorting-tablet: $tablet-breakpoint !default;
$searchsorting-tablet-label-gap: $spacer * 0.5 !default;

.search-sorting {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include media-breakpoint-down(get-breakpoint-key($searchsorting-tablet, -1)) {
    .sorting-label {
      display: none;
    }

    .sorting-dropdown {
      .dropdown-toggle {
        // background-position: center center!important;
      }

      .dropdown-text {
        // display: inline-block;
        // visibility: hidden;
        // overflow: hidden;
        // width: 0;
      }
    }
  }

  @include media-breakpoint-up($searchsorting-tablet) {
    .sorting-label {
      margin: 0 $searchsorting-tablet-label-gap 0 0;
    }

    .sorting-dropdown {
      .dropdown-text {}
    }
  }
}
</style>
